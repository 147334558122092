.notification_main_container {
  width: 100%;
  margin-top: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.notification_row_container {
  /* background-color: red; */
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
  justify-content: center;
  /* align-items: center; */
}
.table_data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 2rem 0rem;
}
@media screen and (max-width: 500px) {
  .notification_tr {
    margin-top: 10px;
  }
}
