.R8trRating_text_row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.R8trRating_text_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.R8trRating_text_div {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.R8trRating_tabs_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.R8trRating__titeltabs {
  font-size: 12px;
  border-radius: 15px;
  /* background-color: #f9fafa; */
  color: #414042;
  border: 1px solid #a7a9ac;
  padding: 10px 25px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.R8trRating__active {
  background-color: black;
  color: white;
}
.R8trRating_inner_row {
  width: 100%;
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.textArea {
  padding-left: 6px;
}
