.level_expertise_main_div {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.level_expertise_row_container {
  display: flex;
  justify-content: center;
}

.level_expertise_heading_div {
  display: flex;
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #b2b3b5;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.category_span {
  background-color: #d40000;
  width: 18px;
  display: flex;
  justify-content: center;
  height: 18px;
  margin-left: 5px;
  border-radius: 50px;
}
.category_span_container {
  display: flex;
  align-items: center;
}
.level_expertise_button{
  width: 25%;
}
@media screen and (max-width: 768px) {
  .level_expertise_button{
    width: 50%;
  }
}