.googlemap_container {
  width: 100% !important;
  height: 90vh !important;
  position: relative;
  top: 0px;
  bottom: 0px;
  z-index: 0;
}
.googlemap_selected_field_container {
  position: absolute;
  top: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  z-index: 1;
  pointer-events: none;
}
.googlemap_selected_field {
  margin-right: 50px;
  height: 42px;
  width: 150px;
  border: 1px solid #a7a9ac;
  border-radius: 0px 25px 0px 25px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.googlemap_selected_field:hover {
  outline: none;
  border: 1px solid #a7a9ac;
  box-shadow: none;
}
.googlemap_selected_field:active {
  outline: none;
  border: 1px solid #a7a9ac;
  box-shadow: none;
}
.googlemap_selected_field:checked {
  outline: none;
  border: 1px solid #a7a9ac;
  box-shadow: none;
}

.googlemap_select {
  color: #5c647c;
  padding: 4px 6px;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
}
select:after {
  content: "";
  position: absolute;
  z-index: 2;
  right: 8px;
  top: 50%;
  margin-right: 10px;
  margin-top: -3px;
  height: 0;
  width: 0;
  border-top: 6px solid #000;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  pointer-events: none;
}

.gm-control-active {
  display: none;
}
