#pills ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
#pills li.active > a {
  /* background: red; */
  text-decoration: none;
}
.CriteriaOption_titeltabs {
  font-size: 12px;
  background-color: #f9fafa;
  padding: 5px 10px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.CriteriaOption_active {
  background-color: black;
  color: white;
}
.pills_ratingValue {
  margin-top: 20px;
}
.pills_ratingValue ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.CriteriaOption_ratingValue_tabs {
  /* background-color: #f9fafa; */
  border: 3px solid #f9fafa;
  border-radius: 10px;
  padding: 5px 15px;
  margin-left: 10px;
  cursor: pointer;
}
.CriteriaOption_ratingValue_active {
  background-color: black;
  color: white;
}
/* a {
  text-decoration: none;
} */
.criteriaOption_image_upload_icon_div {
  width: 100%;
  height: 90px;
  cursor: pointer;
}
.criteriaOption_image_upload_image_preview_div {
  width: 100%;
  height: 90px;
  position: relative;
  cursor: pointer;
  display: flex;

  /* margin-bottom: 100px; */
}
.criteriaOption_upload_image_wrapper {
  display: flex;
  flex-direction: row;
}
.criteriaOption_image_delete_icons {
  position: absolute;
  top:2px;
  right: 5px;
 display: flex;
 width: 100%;
 justify-content: flex-end;
  z-index: 3;
}
.criteriaOption_massage_div {
  width: 100%;
  margin-top: 45px;
  color: #414042;
}

.criteriaOption_massage_div textarea {
  width: 100%;
  height: 80px;
  color: #414042;
  padding-left: 10px;
  padding-top: 5px;
  /* place-content: kvndlkn; */
}
.criteriaOption_massage_div textarea::placeholder {
  font-size: 14px;
  color: #414042;
  /* padding-left: 10px; */
  /* place-content: kvndlkn; */
}
.criteriaOption_button_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 25px;
}
/* @media only screen and (max-width: 1300px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 120px;
    z-index: 3;
  }
}
@media only screen and (max-width: 1200px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 110px;
    z-index: 3;
  }
}

@media only screen and (max-width: 1160px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 105px;
    z-index: 3;
  }
}

@media only screen and (max-width: 1100px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 95px;
    z-index: 3;
  }
}

@media only screen and (max-width: 1000px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 95px;
    z-index: 3;
  }
}
@media only screen and (max-width: 890px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 125px;
    z-index: 3;
  }
}
@media only screen and (max-width: 860px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 112px;
    z-index: 3;
  }
}

@media only screen and (max-width: 768px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 98px;
    z-index: 3;
  }
}

@media only screen and (max-width: 468px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 150px;
    z-index: 3;
  }
}

@media only screen and (max-width: 398px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 138px;
    z-index: 3;
  }
}

@media only screen and (max-width: 360px) {
  .criteriaOption_image_delete_icons {
    margin-top: 25px;
    position: relative;
    bottom: 120px;
    left: 126px;
    z-index: 3;
  }
} */
