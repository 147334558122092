@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,500;0,600;1,300&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.theme-border-color {
  background-color: #d40000;
}
.theme-color {
  color: #d40000;
}

.silver_Color {
  color: #5C647C;
}

.silver_backgrounColor {
  background-color: #a7a9ac;
}

.Font_Family {
  font-family: "Roboto", sans-serif;
}

.silver_backgrounColor {
  background-color: #a7a9ac;
}

.theme_text_color {
  color: #414042;
}
