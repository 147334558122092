.job_price_main_div_style {
  width: 100%;
  background-color: white;
  padding-left: 20px;
  padding-right: 30px;
}

.div_title {
  border-bottom: 1px solid #b2b3b5;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.div_style {
  border-bottom: 1px solid #e0e1e1;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
}

.parent_category_div_style {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  /* margin-top: 10px; */
}
.parent_sub_category_div_style {
  display: flex;
  /* height: 30px; */
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
}

.category_div_style {
  border-bottom: 1px solid #e0e1e1;
  height: 30px;
  /* margin-top: 10px; */
  padding: 16px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 80%;
  font-size: 13px;
  /* overflow-y: scroll; */
}

.input_job_calculator {
  border: none;
  width: 100%;
  font-size: 12px;
}

.input_job_calculator:focus {
  border: none;
  outline: none;
}

.input_job_calculator:active {
  border: none;
  outline: none;
}
.job_price_button {
  width: 25%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkBox_price_div {
  width: 57%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.term_condition_span {
  text-decoration: underline;
  color: rgb(63, 63, 217);
  cursor: pointer;
}
.css-j204z7-MuiFormControlLabel-root {
  margin-left: none !important;
  margin-right: none !important;
}
.term_condition_span_container {
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .checkBox_price_div {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .div_title {
    border-bottom: 1px solid #b2b3b5;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .div_style {
    border-bottom: 1px solid #e0e1e1;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
  }
  .checkBox_price_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .job_price_button {
    width: 40%;
  }
  .term_condition_span_text {
    margin-top: 20px;
  }
}
