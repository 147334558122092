.siglejob_Container {
  width: 100%;
  display: flex;
  justify-content: center;
  /* overflow: scroll; */
}
.siglejob_banner_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.siglejob_banner {
  background-color: black;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 20px;
}
.siglejob_first_title_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #a7a9ac;
}
.siglejob_svg {
  width: 40px;
}
.siglejob_tbody {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.siglejob_tr1 {
  display: flex;
  width: 100%;
  /* height: 40px; */
  padding-top: 15px;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #a7a9ac;
}
.siglejob_tr1 th {
  color: #414042;
  width: 350px;
  font-size: 13px;
  font-weight: bold;
  /* margin-left: 100px; */
}
.siglejob_tr1 td {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  /* margin-left: 100px; */
}
.siglejob_banner_span {
  font-size: 18px;
  font-weight: bold;
  color: white;
  width: 60%;
}
.title_span {
  width: 100%;
}
/* .vertical {
  border-left: 2px solid white;
  height: 30px;
  margin-left: 30px;
  margin-right: 30px;
} */

.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d40000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.switch_input:checked + .slider {
  background-color: #2ab934;
}

.switch_input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch_input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: "OFF";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 12px;
  font-weight: bold;
  font-family: Verdana, sans-serif;
}

.switch_input:checked + .slider:after {
  content: "ON";
  font-size: 14px;
  font-weight: bold;
}
.css-1yjjitx-MuiSwitch-track {
  background-color: red;
}
.MuiSwitch-track {
  background-color: red;
}

@media only screen and (max-width: 412px) {
  .siglejob_tr1 th {
    color: #414042;
    width: 250px;
    font-size: 13px;
    font-weight: bold;
    /* margin-left: 100px; */
  }
}

@media only screen and (max-width: 390px) {
  .siglejob_tr1 th {
    color: #414042;
    width: 225px;
    font-size: 13px;
    font-weight: bold;
    /* margin-left: 100px; */
  }
}
