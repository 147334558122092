.login_container {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/Login/login_background.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.login_row_container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.login-input-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  border-left: none;
}

.login_icon {
  padding: 10px;
  background: transparent;
  border: 2px solid gray;
  border-radius: 45px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  color: white;
  min-width: 50px;
  text-align: center;
}
.login_icon_password {
  padding: 10px;
  background: transparent;
  border: 2px solid gray;
  border-radius: 45px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  color: white;
  min-width: 50px;
  cursor: pointer;
  text-align: center;
}
.login-input-field {
  width: 100%;
  border: 2px solid gray;
  padding: 10px;
  outline: none;
  border-left: none;
  border-radius: 45px;
  border-top-left-radius: 0;
}
.login-input-field-password {
  width: 100%;
  border: 2px solid gray;
  padding: 10px;
  outline: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.login-input-field::placeholder {
  color: black;
}
.login-input-field-password::placeholder {
  color: black;
}
.column_one {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forget-password-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login_p_tage {
  color: #414042;
}
.login_type_div {
  display: flex;
  margin: 10px 0 10px 0;
  justify-content: space-around;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .login_container {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/Login/login_background_mobile.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
  }
  .login-input-field {
    width: 100%;
    border: 2px solid gray;
    border-top: 2px solid gray;
    background-color: transparent;
    padding: 10px;
    outline: none;
    border-left: none;
    border-radius: 45px;
    border-top-left-radius: 0;
    color: white;
  }
  .login-input-field-password {
    color: white;
  }
  .login-input-field::placeholder {
    color: white;
  }
  .login-input-field-password::placeholder {
    color: white;
  }
  .login_p_tage {
    color: white;
  }
  .login_row_container {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  .login_column_two {
    display: none;
  }
}
