.link {
  display: flex;
  align-items: center;
  color: #414042;
  padding: 15px 25px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
  border: none;
  margin-bottom: 10px;
}
.leftbar_main_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.link:hover {
  background: #f4f5f5;
  color: #414042;
  transition: all 0.5s;
}
.active {
  background: black;
  color: white;
  border: none;
}
.icon_link,
.lefbar_link_text {
  font-size: 12px;
  border: none;
  font-weight: bold;
}
.invite_link_tab_text {
  font-size: 12px;
  border: none;
  font-weight: bold;
  color: #414042;
}
.active_invite_link_tab_text {
  font-size: 12px;
  border: none;
  font-weight: bold;
  color: white;
}
.invite_link_tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 25px;
  cursor: pointer;
}
.active_invite_link_tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 25px;
  cursor: pointer;
  background-color: black;
}

.signout_button {
  background-color: #e61d2a;
}
.rating_avatar_buyer {
  width: 65px;
  height: 40px;
  margin-right: 6px;
  cursor: pointer;

  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/images/topbar_icons/avatar.webp");
  background-size: 100% 100%;
}
.rating_avatar {
  width: 48px;
  height: 40px;
  margin-right: 6px;
  cursor: pointer;

  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/images/topbar_icons/avatar.webp");
  background-size: 100% 100%;
}
.rating_avatar_money_icons {
  height: 26px;
}
.rating_avatar_icons_p {
  font-size: 12px;
  line-height: 18px;
  margin-right: 10px;
  position: relative;
  top: 10px;
  font-weight: 700;
}
@media screen and (max-width: 1920px) {
  .sideNavLogoDiv {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 190px;
  }
}
@media screen and (max-width: 1540px) {
  .sideNavLogoDiv {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
  }
}
@media screen and (max-width: 1396px) {
  .sideNavLogoDiv {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1360px) {
  .sideNavLogoDiv {
    width: 100%;
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 1300px) {
  .sideNavLogoDiv {
    width: 100%;
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 40px; */
  }
}

@media screen and (max-width: 1100px) {
  .lefbar_eight_logo {
    display: none;
    /* margin-top: 40px; */
  }
}
