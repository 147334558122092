.current_job_detail_buyer_main_div {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.current_job_detail_buyer_row {
  display: flex;
  justify-content: center;
}

.current_job_detail_buyer_heading_div {
  width: 100%;
  border-bottom: 1px solid #a7a9ac;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current_job_detail_buyer_price_div {
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  /* height: 70px; */
}

.current_job_detail_buyer_price_instruction_div {
  border-right: 1px solid #b2b3b5;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.current_job_detail_buyer_price_cost_div {
  width: 50%;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}

.current_job_detail_buyer_table_heading_div {
  color: #414042;
  font-size: 20px;
  width: 100%;
  border-bottom: 1px solid #b2b3b5;
}
