#pills ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
#pills li.active > a {
  /* background: red; */
  text-decoration: none;
}
.CriteriaOption_titel_tabs {
  font-size: 14px;
  background-color: #f9fafa;
  padding: 5px 10px;
  margin-right: 10px;
  /* margin-top: 10px; */
  cursor: pointer;
}
.CriteriaOption_active {
  background-color: black;
  color: white;
}
.pills_rating_value {
  height: 90px;
  margin-top: 20px;
  border-radius: 10px;

}
.pills_rating_value ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.CriteriaOption_rating_value_tabs {
  /* background-color: #f9fafa; */
  border: 3px solid #f9fafa;
  border-radius: 10px;
  padding: 5px 15px;
  margin-right: 10px;
  cursor: pointer;
}
.CriteriaOption_ratingValue_active {
  background-color: black;
  color: white;
}
/* a {
  text-decoration: none;
} */
.criteriaOption_image_upload_icon_div {
  width: 100%;
  height: 90px;
  cursor: pointer;
}
.criteriaOption_image_upload_image_preview_buyer {
  width: 100%;
  /* height: 90px; */
  cursor: pointer;
  /* position: relative; */
  /* margin-bottom: 100px; */
}
.criteriaOption_upload_image_wrapper {
  display: flex;
  flex-direction: row;
}
.criteriaOption_massage_div {
  width: 100%;
  margin-top: 45px;
  color: #414042;
}

.criteriaOption_massage_div textarea {
  width: 100%;
  height: 80px;
  color: #414042;
  padding-left: 10px;
  padding-top: 5px;
  /* place-content: kvndlkn; */
}
.criteriaOption_massage_div textarea::placeholder {
  font-size: 14px;
  color: #414042;
  /* padding-left: 10px; */
  /* place-content: kvndlkn; */
}
.criteriaOption_button_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 25px;
}
.image_grid_item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.criteria_option_section {
  background-color: #f9fafa !important;
  padding: 10px 12px;
  border-radius: 10px;
}
.criteria_option_section_div {
  padding: 10px 12px;
}
.text_field_Grid_container {
  margin-top: -30px !important;
}
.text_field_div {
  background-color: #f9fafa !important;
  padding: 12px 12px;
  line-height: 1;
  border-radius: 10px;
}
.text_field_div_title{
  margin-bottom: 8px !important;
}
.text_field_div_text{
  margin-bottom: 0px !important;
}