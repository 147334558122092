.job_detail_main_div {
  width: 100%;
  background-color: white;
  padding-left: 20px;
  padding-right: 30px;
}

.div_title_job_details {
  border-bottom: 1px solid #b2b3b5;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.div_style_job_details {
  border-bottom: 1px solid #e0e1e1;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.input_job_details {
  border: none;
  width: 100%;
  font-size: 12px;
}

.input_job_details:focus {
  border: none;
  outline: none;
}

.input_job_details:active {
  border: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .div_title_job_details {
    border-bottom: 1px solid #b2b3b5;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .input_job_details::placeholder{
    color: #b2b3b5;
    font-size: 12px;
  }
  .div_style_job_details {
    border-bottom: 1px solid #e0e1e1;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}