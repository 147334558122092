.main_container {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/reset_password/reset_password_background.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.row_container {
  display: flex;
  justify-content: center;
}

.input-container {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  border-left: none;
}
.icon {
  padding: 10px;
  background: transparent;
  border: 1px solid gray;
  border-radius: 45px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  color: white;
  min-width: 50px;
  text-align: center;
}
.input-field {
  width: 100%;
  border: 1px solid gray;
  padding: 10px;
  outline: none;
  border-left: none;
  border-radius: 45px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
