.navlink_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.navlink_avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/images/topbar_icons/avatar.webp");
  background-size: 100% 100%;
}
.navlink_avatar_profile {
  background-color: #edeeee;
}
.navlink_list_icons {
  color: white;
}
.MuiAvatar-colorDefault {
  background-color: #edeeee;
}
.MuiAvatar-root {
  background-color: #edeeee;
}
.navlink_list_icons_p {
  font-size: 14px;
  color: #263238;
  line-height: 18px;
  margin-right: 10px;
  position: relative;
  top: 10px;
}
.notification_container_span {
  position: relative;
}
.notification_alert {
  width: 15px;
  height: 15px;
  text-align: center;
  background-color: red;
  z-index: 1;
  position: absolute;

  left: 30px;
  bottom: 20px;
  border-radius: 25px;
}
.sidebar_menue_button {
  display: none;
}
.popover-body {
  padding: 0rem !important;

  padding-left: 0rem !important;
}

.notification_container {
  display: flex;
  width: 100%;
  padding: 0rem 1rem;
  background-color: white;
  overflow: hidden;
  margin-top: 1px;
  border-bottom: 1px solid #ebebeb;
}

.navlink_avatar2 {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/images/topbar_icons/avatar.webp");
  background-size: 100% 100%;
}
