.forgotpassword_container {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/forget_password/Forget-password_background.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.forgotpassword_row_container {
  /* background-color: red; */
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.forgotpassword-input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  border-left: none;
}
.forgotpassword_icon {
  padding: 10px;
  background: transparent;
  border: 2px solid gray;
  border-radius: 45px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  color: white;
  min-width: 50px;
  text-align: center;
}
.forgotpassword-input-field {
  width: 100%;
  border: 2px solid gray;
  padding: 10px;
  outline: none;
  border-left: none;
  border-radius: 45px;
  border-top-left-radius: 0;
}

.forgotpassword-input-field::placeholder {
  color: black;
  /* opacity: 1; */
}
@media only screen and (max-width: 600px) {
  .forgotpassword_container {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/images/forget_password/Forget-password_background_mobile.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .forgotpassword-input-field {
    width: 100%;
    border: 2px solid gray;
    border-top: 2px solid gray;
    background-color: transparent;
    padding: 10px;
    outline: none;
    border-left: none;
    border-radius: 45px;
    border-top-left-radius: 0;
    color: white;
  }
  .forgotpassword_row_container {
    /* background-color: red; */
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  .forgotpassword_p_tage {
    color: white;
  }
  ::placeholder {
    color: white;
    opacity: 1;
  }
  .forgotpassword_column_two {
    display: none !important;
  }
  /* :-ms-input-placeholder { 
   color: white;
  }
  
  ::-ms-input-placeholder {
   color: white;
  } */
  .forgotpassword_row_container {
    /* background-color: red; */
    display: flex;
    /* flex-direction: column; */
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
}
