.nav-tabs .nav-link {
  background-color: transparent !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  cursor: pointer;
  font-size: 14px;
}

.nav-tabs .nav-link.active {
  border-bottom: solid 4px black !important;
}

.row_r8tr {
  display: flex;
  justify-content: center;
}

.nav_r8tr_tab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hire_r8tr_row_style {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 55px;
  background-color: #fbfdfb;
  border-bottom: 1px solid #ebebe9;
}

.hire_r8tr_grid_tab_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.nav_imgheading {
  height: 50px;
  width: 50px;
}

.tab_heading_text {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}
.hire_r8tr_main_div {
  height: 100vh;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.priceCalculator {
  height: 100vh;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/hireR8trImages/jobPriceCalculator.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.jobDetail {
  height: 100vh;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/hireR8trImages/jobDetail.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.addressDetail {
  height: 100vh;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/hireR8trImages/addressDetail.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.contactDetail {
  height: 100vh;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/images/hireR8trImages/contactDetail.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeTwo {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeThree {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeFour {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .hire_r8tr_grid_tab_div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .hire_r8tr_row_style {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    background-color: #fbfdfb;
    border-bottom: 1px solid #ebebe9;
  }
  .tab_heading_text {
    display: flex;
    flex-direction: column;
    font-size: 11px;
  }
  .nav_imgheading {
    height: 35px;
    width: 35px;
  }
  .hireR8tr_price_calculator {
    display: none;
  }
  .tab_heading_text {
    margin-top: 5px;
  }
}
