.contact_detail_main_div {
  width: 100%;
  background-color: white;
  padding-left: 20px;
  padding-right: 30px;
}

.div_title_contact_details {
  border-bottom: 1px solid #b2b3b5;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.div_style_contact_details {
  border-bottom: 1px solid #e0e1e1;
  height: 50px;
  display: flex;
  align-items: center;
}
.div_style_contact_details_tab {
  height: 50px;
  display: flex;
  align-items: center;
}

.input_contact_details {
  border: none;
  width: 50%;
}

.input_contact_details:focus {
  border: none;
  outline: none;
}

.input_contact_details:active {
  border: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textarea_contact_detail {
  width: 100%;
  /* color: #a7a9ac; */
  resize: none;
  border: 1px solid #e0e1e1;
  font-size: 10px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .div_title_contact_details {
    border-bottom: 1px solid #b2b3b5;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .input_job_calculator::placeholder {
    color: #b2b3b5;
    font-size: 12px;
  }
  .textarea_contact_detail::placeholder {
    color: #b2b3b5;
    font-size: 12px;
  }
  .div_style_contact_details {
    border-bottom: 1px solid #e0e1e1;
    height: 40px;
    display: flex;
    align-items: center;
  }
}
