.Support_text_row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.Support_text_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Support_inner_row {
  width: 100%;
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
