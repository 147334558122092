.buyerdashboard_container {
  /* background-image: url("../../assets/images/my-jobs-bg-image.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* position: fixed; */
  /* overflow: scroll; */
  /* height: 100vh; */
}
