.area_expertise_main_div {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.area_expertise_row_container {
  display: flex;
  justify-content: center;
}

.area_expertise_heading_div {
  display: flex;
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #b2b3b5;
  justify-content: space-between;
}

.area_expertise_category_div {
  margin-top: 10px;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.area_expertise_subCategory_div {
  width: 100%;
  border-bottom: 1px solid #e0e1e1;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.area_expertise_button{
  width: 25%;
}
@media screen and (max-width: 768px) {
  .area_expertise_button{
    width: 50%;
  }
}