.modalContainer {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: last baseline;
  position: absolute;
}
.modal {
  width: 40%;
  height: 50%;
  overflow: auto;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  width: 350px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 550px !important;
}
.cs-conversation-header__content .cs-conversation-header__user-name {
  background-color: #000 !important;
  color: white !important;
}
.cs-message-input__content-editor-wrapper {
  background-color: #edebeb !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.cs-message-input__content-editor {
  background-color: #edebeb !important;
}
.MuiSpeedDial-actions {
  /* margin-left: 20px !important; */
  padding-left: 0px !important;
  /* background-color: #000 !important; */
  box-shadow: none !important;
}
.cs-message--outgoing .cs-message__content {
  background-color: #c6e3fa !important;
}
.cs-message--incoming .cs-message__content {
  background-color: #edebeb !important;
}
.audio_player {
  width: 220px !important;
}
.timer_container {
  position: absolute;
  top: 38%;
  left: 42%;
  font-size: 16px;
}
.timer_container_Two {
  position: absolute;

  left: 25%;
  bottom: 8%;
  font-size: 16px;
}
#bars {
  position: absolute;
  top: 77%;
  left: 137%;
  height: 14px;
  width: 180px;
  margin: -30px 0 0 -20px;
  transform: scale(2) translateX(-50%);
}

.bar {
  position: absolute;
  bottom: 0px;
  height: 3px;
  width: 1px;
  background: #000;
  animation: sound 0ms -800ms linear infinite alternate;
  transform: translateY(50%);
}
#bars_two {
  position: absolute;
  top: 90%;
  left: 132%;
  height: 14px;
  width: 180px;
  margin: -30px 0 0 -20px;
  -webkit-transform: scale(2) translateX(-50%);
  transform: scale(2) translateX(-50%);
}
@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 14px;
  }
}
.css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  width: 0px !important;
  height: 0px !important;
  background-color: black !important;
  color: white !important;
  margin-left: 4px !important;
  min-height: 0px !important;
  box-shadow: none !important;
  padding: 15px 15px !important;
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  cursor: pointer;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -webkit-text-decoration: none !important;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 50% !important;
  z-index: 1050 !important;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important;
}
.css-1e2cad8-MuiButtonBase-root-MuiFab-root-MuiSpeedDialAction-fab {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -webkit-text-decoration: none !important;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 50% !important;
  background-color: #edebeb !important;
  width: 0px !important;
  height: 0px !important;
  min-height: 0px !important;
  padding: 15px 15px !important;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 0.8s !important;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.8s !important;
  opacity: 1;
}
.css-1ui0s45-MuiSpeedDial-root .MuiSpeedDial-actions {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: -1px !important;
  padding-left: 48px;
}
.file_icons {
  font-size: 22px !important;
  margin-right: 6px !important;
}
.a_tage {
  text-decoration: none;
  color: #000;
}
.a_tage:hover {
  text-decoration: none;
  color: #000;
}
.message_container {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
  width: 100%;
  font-family: "Roboto", sans-serif;
}
.cs-loader::after {
  top: 8px;
  left: 57%;
}


.css-r9uhzn{
  background: black !important;
  height: 32px !important;
  width: 34px !important;
  margin-left: 8px !important;
}
.css-n3m1km .MuiSpeedDial-actions{
  margin-left: 4px !important;
}
.css-r8u8y9{
  padding: 0px !important;
}
.css-1u93ig5{
  height: 32px !important;
  width: 34px !important;
  background-color: #edebeb !important;
}
/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  bottom: 10px;
  top: 0px;
} */