.buyer_past_job_main_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buyer_past_job_grid_container {
  border-bottom: 1px solid #a7a9ac;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buyer_past_job_1st_grid_style {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.past_job_title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.past_job_title_year_span {
  background-color: #a7a9ac;
  border-radius: 25px;
  font-size: 10px;
  width: 25%;
  padding-left: 5px;
}
.pastjob_tbody_buyer {
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 15px;
}
.pastjob_tr_buyer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buyer_past_job_3rd_grid {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.pastjobs_td_status {
  width: 13%;
}

@media screen and (max-width: 1190px) {
  .pastjobs_td_status {
    width: 16%;
  }
}
@media screen and (max-width: 990px) {
  .pastjobs_td_status {
    width: 23%;
  }
}
@media screen and (max-width: 900px) {
  .pastjobs_td_status {
    width: 30%;
  }
}
@media screen and (max-width: 500px) {
  .pastjobs_td_status {
    width: 30%;
  }
  .buyer_past_job_status {
    font-size: 14px;
  }
}
@media screen and (max-width: 420px) {
  .pastjobs_td_status {
    width: 32%;
  }
  .buyer_past_job_status {
    font-size: 14px;
  }
}
@media screen and (max-width: 390px) {
  .pastjobs_td_status {
    width: 35%;
  }
}
