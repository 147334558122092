.service_Area_main_div {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.service_Area_row_container {
  display: flex;
  justify-content: center;
}

.heading_service_area_div {
  display: flex;
  width: 100%;
  padding: 5px;
  justify-content: space-between;
}

.address_edit_div {
  width: 100%;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  font-weight: bold;
}

.input_field_service_area_information {
  border: none;
  font-weight: bold;
  width: 100%;
  height: 30px;
  padding: 10px;
  resize: none;
}

.input_field_service_area_information:disabled {
  background-color: white;
}
.service_area_button{
  width: 25%;
}
@media screen and (max-width: 768px) {
  .service_area_button{
    width: 50%;
  }
}