.profile_information_main_div {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.profile_information_heading_div {
  display: flex;
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #b2b3b5;
  justify-content: space-between;
}
.delete_button_mobile_screen {
  display: none;
}
.profile_information_row {
  display: flex;
  justify-content: center;
}

.input_field_profile_information {
  border: none;
  font-weight: bold;
  width: 100%;
  height: 30px;
  padding: 10px;
  resize: none;
}

.input_field_profile_information:focus {
  border: 1px solid #a7a9ac;
  outline: none;
}

.input_field_profile_information:active {
  border: 1px solid #a7a9ac;
  outline: none;
}

.input_field_profile_information_textfield {
  border: none;
  font-weight: bold;
  width: 100%;
  padding: 10px;
  resize: none;
}

.input_field_profile_information_password {
  border: none;
  font-weight: bold;
  width: 100%;
  height: 30px;
  padding: 10px;
  font-size: 14px;
}

.input_field_profile_information:disabled {
  background-color: white;
}

.input_field_profile_information_textfield:disabled {
  background-color: white;
}

.profile_information_div_password {
  width: 100%;
  height: 50px;
  /* border-bottom: 1px solid #e0e0e0; */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .profile_information_button_span {
  width: 60%;
  display: flex;
  justify-content: space-between;
} */
.profile_information_profile_pic_div {
  width: 200px;
  height: 200px;
  background-image: url("../../../assets/images/personal_info/profile-pic-upload-icon.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile_information_button_span {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.camara_icon {
  position: absolute;
  bottom: -15px;
  /* top:0px; */
  /* left: 50px; */
  z-index: 9;
  cursor: pointer;
}
.profile_information_heading_span {
  font-weight: bold;
}
.personal_info_button {
  width: 25%;
}
@media screen and (max-width: 768px) {
  .personal_info_button {
    width: 50%;
  }
  .delete_button_mobile_screen {
    display: block;
    width: 100%;
    justify-content: end;
    align-items: center;
  }
  .delete_button_web_screen {
    display: none;
  }
}
@media screen and (max-width: 395px) {
  .profile_information_button_span {
    /* width: 80%; */
    display: flex;
    justify-content: flex-end;
  }
  .profile_information_heading_span {
    font-weight: bold;
    font-size: 14px;
  }
  .personal_info_button {
    width: 50%;
  }
  .delete_button_mobile_screen {
    display: block;
    width: 100%;
    justify-content: end;
    align-items: center;
  }
  .delete_button_web_screen {
    display: none;
  }
}
