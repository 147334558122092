.buyer_current_job_main_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: "1100px";
}

.currentjob_tbody_buyer1 {
  width: 100%;
  margin-top: 10px;
  /* height: 50px; */
  justify-content: center;
  align-items: center;
  /* background-color: white; */
  border-radius: 15px;
  cursor: pointer;
}
.currentjob_tbody_buyer {
  width: 100%;
  margin-top: 15px;
  /* height: 50px; */
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
}
.currentjob_tr_buyer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.currentjob_td_buyer {
  padding: 10px 10px;
}

.currentjob_td_status {
  width: 12%;
}
.buyer_current_job_discription {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0px;
  line-break: loose;
}
.buyer_current_job_price{
  font-size: 16px;
}
.buyer_current_job_status{
  font-size: 16px;
}
@media screen and (max-width: 1120px) {
  .currentjob_td_status {
    width: 15%;
  }
}
@media screen and (max-width: 990px) {
  .currentjob_td_status {
    width: 20%;
  }
}
@media screen and (max-width: 900px) {
  .currentjob_td_status {
    width: 25%;
  }
}
@media screen and (max-width: 500px) {
  .currentjob_td_status {
    width: 30%;
  }
}
@media screen and (max-width: 420px) {
  .currentjob_td_status {
    width: 32%;
  }
  .buyer_current_job_status{
    font-size: 15px;
  }
}
@media screen and (max-width: 390px) {
  .currentjob_td_status {
    width: 35%;
  }
}
