.buyer_job_main_div {
  background-image: url("../../../assets/images/my-jobs-bg-image.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
}

.buyer_job_row_style {
  width: 100%;
  height: 90vh;
  display: flex;
}

.buyer_job_active {
  color: white;
  border-bottom: 3px solid white;
  font-weight: bold;
  cursor: pointer;
}
.buyer_job_inactive {
  font-weight: bold;
  cursor: pointer;
  color: #7d8ea3;
}
@media screen and (max-width: 1120px) {
  .buyer_job_row_style {
    width: 95%;
    height: 90vh;
    display: flex;
  }
}

@media screen and (max-width: 960px) {
  .buyer_job_row_style {
    width: 95%;
    height: 90vh;
    display: flex;
  }
}
@media screen and (max-width: 900px) {
  .buyer_job_row_style {
    width: 85%;
    height: 90vh;
    display: flex;
  }
}
@media screen and (max-width: 420px) {
  .buyer_job_row_style {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
  }
}
