.BuyerRatingR8tr_text_row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.BuyerRatingR8tr_text_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BuyerRatingR8tr_text_div {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.BuyerRatingR8tr_tabs_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.BuyerRatingR8tr__tip_titeltabs {
  font-size: 12px;
  border-radius: 15px;
  /* background-color: #f9fafa; */
  color: #414042;
  border: 1px solid #ebebeb;
  padding: 10px 25px;
  margin-left: 10px;
  /* margin-top: 10px; */
  cursor: pointer;
}
.BuyerRatingR8tr__titeltabs {
  font-size: 12px;
  border-radius: 15px;
  /* background-color: #f9fafa; */
  color: #414042;
  border: 1px solid #ebebeb;
  padding: 10px 25px;
  margin-left: 10px;
  /* margin-top: 10px; */
  cursor: pointer;
}
.BuyerRatingR8tr__active {
  background-color: black;
  color: white;
}
.R8trRating_inner_textarea_row {
  width: 100%;
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.R8trRating_inner_row {
  width: 100%;
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.BuyerRatingR8tr__inputtabs {
  font-size: 12px;
  border-radius: 15px;
  /* background-color: #f9fafa; */
  color: #414042;
  border: 1px solid #ebebeb;
  padding: 5px 10px;
  margin-left: 10px;
  /* margin-top: 10px; */
  width: 80px;
  /* cursor: pointer; */
}
.BuyerRatingR8tr__input_tabs {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
/* .textArea::placeholder {
  padding-left: 6px;
} */
.textArea {
  padding-left: 10px;
}
.save_money_input {
  padding-left: 6px;
  border: 1px solid #ebebeb;
  height: 45px;
  width: 55%;
}
.checkbox_col {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.checkbox_col2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px !important;
}
.next_button {
  height: 45px !important;
  border-radius: 0px 15px 0px 15px !important;
  background-color: #e61d2a !important;
  color: white !important;
  width: 20% !important;
}

@media only screen and (max-width: 830px) {
  .BuyerRatingR8tr_text_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .save_money_input {
    padding-left: 6px;
    border: 1px solid #ebebeb;
    height: 45px;
    width: 80%;
  }
  .BuyerRatingR8tr__titeltabs {
    font-size: 12px;
    border-radius: 15px;
    /* background-color: #f9fafa; */
    color: #414042;
    border: 1px solid #ebebeb;
    padding: 10px 25px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }

  .BuyerRatingR8tr__inputtabs {
    font-size: 12px;
    border-radius: 15px;
    /* background-color: #f9fafa; */
    color: #414042;
    border: 1px solid #ebebeb;
    padding: 15px 10px;
    margin-left: 10px;
    margin-top: 10px;
    width: 80px;
    /* cursor: pointer; */
  }
  .BuyerRatingR8tr__active {
    background-color: black;
    color: white;
  }
  .next_button {
    height: 45px !important;
    border-radius: 0px 15px 0px 15px !important;
    background-color: #e61d2a !important;
    color: white !important;
    width: 40% !important;
  }
  .R8trRating_text_col {
    width: 100%;
  }
  .BuyerRatingR8tr__tip_titeltabs {
    font-size: 12px;
    border-radius: 15px;
    /* background-color: #f9fafa; */
    color: #414042;
    border: 1px solid #ebebeb;
    padding: 10px 15px;
    margin-left: 10px;
    /* margin-top: 10px; */
    cursor: pointer;
  }
  .R8trRating_inner_row {
    width: 100%;
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .checkbox_col {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
  }
  .checkbox_col1 {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
  }
  .nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}
@media only screen and (max-width: 768px) {
  .BuyerRatingR8tr_text_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .save_money_input {
    padding-left: 6px;
    border: 1px solid #ebebeb;
    height: 45px;
    width: 80%;
  }
  .BuyerRatingR8tr__titeltabs {
    font-size: 12px;
    border-radius: 15px;
    /* background-color: #f9fafa; */
    color: #414042;
    border: 1px solid #ebebeb;
    padding: 10px 25px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }

  .BuyerRatingR8tr__inputtabs {
    font-size: 12px;
    border-radius: 15px;
    /* background-color: #f9fafa; */
    color: #414042;
    border: 1px solid #ebebeb;
    padding: 15px 10px;
    margin-left: 10px;
    margin-top: 10px;
    width: 80px;
    /* cursor: pointer; */
  }
  .BuyerRatingR8tr__active {
    background-color: black;
    color: white;
  }
  .next_button {
    height: 45px !important;
    border-radius: 0px 15px 0px 15px !important;
    background-color: #e61d2a !important;
    color: white !important;
    width: 40% !important;
  }
  .R8trRating_text_col {
    width: 100%;
  }
  .BuyerRatingR8tr__tip_titeltabs {
    font-size: 12px;
    border-radius: 15px;
    /* background-color: #f9fafa; */
    color: #414042;
    border: 1px solid #ebebeb;
    padding: 10px 15px;
    margin-left: 10px;
    /* margin-top: 10px; */
    cursor: pointer;
  }
  .checkbox_col {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
  }
  .checkbox_col1 {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
  }
  .nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}
