.job_container_width {
  width: 50%;
  height: 89vh;
  transition: all 0.5s;
  position: relative;
  z-index: 9;
  flex-direction: column;
  display: flex;
  align-items: center;
  overflow-y: scroll;
}
.job_container_width2 {
  width: 2%;
  background-color: rgb(248, 248, 248);
  transition: all 0.5s;
}

.job_container_width::-webkit-scrollbar {
  width: 2px;
}
.job_container_width::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.job_container_width::-webkit-scrollbar-thumb {
  background: #888;
}
.top_section {
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 15px;
}
.logo {
  color: #000;
  text-align: center;
  margin-left: 10px;
  margin-top: 13px;
  font-weight: bold;
}

.currentjob_button_backgroun_container1 {
  width: 90%;
  background-image: url("../../../assets/images/current_jobs/distance.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
  margin-top: 20px;
}
.currentjob_button_backgroun_container2 {
  width: 90%;
  background-image: url("../../../assets/images/current_jobs/value.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.currentjob_button_backgroun_container1_button {
  color: white;
}
.currentjob_button_backgroun_container2_button {
  color: #5c647c;
}
.currentjob_button_distance {
  background: transparent;
  width: 50%;
  height: 40px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}
.currentjob_button_value {
  border: none;
  background: transparent;

  width: 50%;
  height: 40px;
  cursor: pointer;
  font-weight: bold;
}
.jobs_bars {
  z-index: 9;
  position: fixed;
  color: #000;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ebebeb;
  padding: 5px 12px;
}
.jobs_bars_margin_open {
  margin-left: -30px;
  margin-top: 5px;

  cursor: pointer;
}
.jobs_bars_margin_close {
  margin-left: -30px;
  cursor: pointer;
}
.currentjob_tr_error {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.currentjob_tbody {
  width: 90%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 0px 15px 0px 15px;
  cursor: pointer;
}
.currentjob_tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.currentjob_td {
  padding: 4px 12px;
}
.currentjob_svg {
  width: 40px;
}
.pic {
  width: 100%;
  height: 100%;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.square {
  width: 40px;
  height: 40px;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  overflow: hidden;
}
@media only screen and (max-width: 1180px) {
  .job_container_width {
    width: 70%;
    height: 89vh;
    transition: all 0.5s;
    position: relative;
    z-index: 9;
    flex-direction: column;
    display: flex;
    align-items: center;
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 1080px) {
  .job_container_width {
    width: 70%;
    height: 89vh;
    transition: all 0.5s;
    position: relative;
    z-index: 9;
    flex-direction: column;
    display: flex;
    align-items: center;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 968px) {
  .job_container_width {
    width: 100%;
    height: 89vh;
    transition: all 0.5s;
    position: relative;
    z-index: 9;
    flex-direction: column;
    display: flex;
    align-items: center;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 890px) {
  .job_container_width {
    width: 120%;
    height: 89vh;
    transition: all 0.5s;
    position: relative;
    z-index: 9;
    flex-direction: column;
    display: flex;
    align-items: center;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 820px) {
  .job_container_width {
    width: 135%;
    height: 89vh;
    transition: all 0.5s;
    position: relative;
    z-index: 9;
    flex-direction: column;
    display: flex;
    align-items: center;
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 420px) {
  .job_container_width {
    width: 310%;
    height: 89vh;
    transition: all 0.5s;
    position: relative;
    z-index: 9;
    flex-direction: column;
    display: flex;
    align-items: center;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 360px) {
  .job_container_width {
    width: 450%;
    height: 89vh;
    transition: all 0.5s;
    position: relative;
    z-index: 9;
    flex-direction: column;
    display: flex;
    align-items: center;
    overflow-y: scroll;
  }
}
