.main_div_hire_a_r8tr {
  background: url(../../../assets/images/hireR8trImages/bg.webp) no-repeat
    center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hire_a_r8tr_main_box {
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 20%, 80% 0);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 20%, 80% 0);
  margin-top: 50px;
  margin-bottom: 50px;
  width: 50%;
  background-color: #f4f4f4;
}

@media only screen and (max-width: 600px) {
  .hire_a_r8tr_main_box {
    width: 100%;
  }
}
