.VideoUpload_main_row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.VideoUpload_map_col {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.videoField {
  width: 0px;
  height: 0px;
}
.criteriaOption_video_upload_image_preview_div {
  width: 100%;
  height: 90px;
  position: relative;
  cursor: pointer;
  display: flex;

  /* margin-bottom: 100px; */
}
.criteriaOption_video_delete_icons {
  position: absolute;
  top:2px;
  right: 5px;
 display: flex;
 width: 100%;
 justify-content: flex-end;
  z-index: 3;
}