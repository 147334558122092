.r8ting_title_span {
    border-bottom: 1px solid #a7a9ac;
  }
  .r8ting_main_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }
  .r8ting_main_row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .r8ting_main_col {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .vehical_sep_div {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .R8tingCategoryList_tabs_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
  .R8tingCategoryList_tabs {
    width: 100%;
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    padding-right: 50px;
    height: 50px;
    cursor: pointer;
  }
  .R8tingCategoryList_tabs_Active {
    width: 100%;
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid #a7a9ac;
    background-color: black;
    height: 50px;
  }
  .R8tingCategoryList_tabs_Active li {
    color: white;
  }
  
  .R8tingCategoryList_tabs_li {
    display: table-cell;
    vertical-align: middle;
    word-wrap: break-word;
    color: #414042;
  }
  .R8tingCategoryList_tabs_li2 {
    display: table-cell;
    text-align: end;
    vertical-align: middle;
    word-wrap: break-word;
    color: #414042;
    padding-right: 10px;
    -ms-text-combine-horizontal: all;
  }
  .R8tingCategoryList_tabs_li2 p{
    margin-right: 50px;
  }
  /* .R8tingCategoryList_tabs_li_span{
    display: flex;
    padding: 0px 20px;
  } */
  .R8tingCategoryList_tabs_color:nth-of-type(odd) {
    background-color: #f9fafa;
  }
  
  @media only screen and (max-width: 490px) {
    .R8tingCategoryList_tabs_li2 p{
      margin-right: 40px;
    }
    .vehical_sep_div {
      width: 100%;
      display: flex;
      text-align: center;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  
  }